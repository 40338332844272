import "../css/GithubFloater.css";
import githubIcon from "../media/github-icon.svg";
function GithubFloater()
{
    return(
        <div className="github2">
            <a href="https://www.github.com/Servatom/">
                <img src={githubIcon} alt="Github Icon"/>
            </a>
        </div>  
    );
}

export default GithubFloater;
import "../css/Topic.css";

function Topics(props)
{

    return(
        <span className="topic">{props.topic}</span>
    )
}

export default Topics;
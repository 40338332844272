import "../css/UnderConstruction.css";
import devgif from "../media/devgif.gif"

function UnderConstruction(){

    // const gifURL = "https://lucsoninfotech.com/wp-content/uploads/2020/12/custom-erp-software-development.gif";
    return(
        <div>
            <div className="construction">
                <img src={devgif} alt="Under Development GIF"></img>
                <p>
                    {/* OOPS! Website Under Development. */}
                    We are still improving our website!
                    <br/>
                    <br/>
                    {/* <span>Till then, check us out on Github!</span> */}
                    <span>While we do that, you can scroll down to see our recent and ongoing <a href="#projects" >projects</a>!</span>
                    <br/>
                    {/* <span className="pressG">Press <code>G</code> to go to our Github page.</span> */}
                </p>
            </div>
        </div>
    );
}

export default UnderConstruction;
import "../css/Project.css";
import ProjectLeft from "./ProjectLeft";
import ProjectRight from "./ProjectRight";
import {useEffect, useState} from 'react';
import { CLIENT_ID, CLIENT_SECRET } from "../Constants";

function Projects()
{
    const [dataList, setDataList] = useState(()=>[]);
    
    useEffect(()=>{
        
        let options = {
            headers: {
                'User-Agent': 'request'
              }
        };

        fetch(`https://api.github.com/orgs/Servatom/repos?sort=created&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`, options)
        .then(response => response.json())
        .then(data => setDataList(data));
                 
    },[])
    

    let count=0;
    
    return(

        
        <section className="projects" id="projects">
            <h2 id="projectHeading">Our Projects</h2>
            {
                dataList.map((obj)=>{

                    
                    const repoData=
                    {
                        title: obj.name,
                        description: obj.description,
                        url: obj.html_url,
                        language: obj.language,
                        homepage: obj.homepage,
                    }
                    

                    if(count%2==0)
                    return(<ProjectLeft key={obj.id} number={++count} data={repoData}/>)
                
                    else
                    return(<ProjectRight key={obj.id} number={++count} data={repoData}/>)
                })
            }
        </section>
    );
}

export default Projects;
import "./css/Header.css";
import './index.css';
import Preloader from "./components/Preloader";
import UnderConstruction from "./components/UnderConstruction";
import GithubFloater from "./components/GithubFloater";
import Projects from "./components/Projects";
import Heading from "./components/Heading";
import Snowfall from "react-snowfall";
import { useState } from "react";
import { useEffect } from "react/cjs/react.development";

function App() {
  
  return (
    <>
    {/* <Preloader/>  */}
    {/* <div className="snowfallContainer">
      <Snowfall snowflakeCount={75}/>
    </div> */}
    <Heading/>
    <Projects/>
    <div style={{textAlign:"center", marginTop:"70px"}}>
      <span className="pressG">Press <code>G</code> to go to our Github page.</span>
    </div>
    <GithubFloater/>
    </>
  );
}

export default App;
